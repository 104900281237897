import React from 'react';
import { Layout } from '../components/shared/Layout';

const CookiePolicyPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Privacy policy</h1>
            <p>
              This is the Privacy Policy of DELAPLUMES (“
              <strong>DELAPLUMES</strong>”, “we,” “us” or “our”), a
              collaboration of volunteers having no fixed address. DELAPLUMES is
              a collaboration of volunteers and therefor not registered in the
              Trade registry of the Chamber of Commerce. Our Privacy Policy
              explains how we collect, use, share and protect information in
              relation to our website www.delaplumes.nl (the “
              <strong>Service</strong>“), and your choices about the collection
              and use of your information.
            </p>
            <p>
              By using the Service, you understand and agree to the collection
              and use of information in accordance with this Privacy Policy. Our
              Privacy Policy applies to all visitors, users, and others who
              access the Service (“
              <strong>Users</strong>“).
            </p>

            <h2>What kinds of information do we collect?</h2>
            <p>&nbsp;</p>
            <h2>Information you provide</h2>
            <p>
              We collect the following information you provide us with directly:
            </p>
            <ol>
              <li>Your name;</li>
              <li>Your phone number;</li>
              <li>Your address;</li>
              <li>
                Communications between you and DELAPLUMES (e.g., we may send you
                Service-related emails);
              </li>
            </ol>
            <p>&nbsp;</p>
            <h2>Log file information</h2>
            <p>
              We collect information that your browser sends whenever you visit
              our Service. This log file information may include information
              such as your computer’s Internet Protocol address, browser type,
              browser version, the pages of our Service that you visit, the time
              and date of your visit, the time spent on those pages and other
              statistics.
            </p>
            <p>&nbsp;</p>
            <h2>
              Analytics services (non-personally identifiable information only)
            </h2>
            <p>
              We use third-party analytics tools to help us measure traffic and
              usage trends for the Service. These tools collect information sent
              by your device or our Service, including the web pages you visit,
              add-ons, and other information that assists us in improving the
              Service. The tools use ‘cookies’, which are text files placed on
              your device, to collect your log information and behavior
              information in an anonymous form.&nbsp;We collect and use this
              analytics information with analytics information from other Users
              so that it cannot reasonably be used to identify any particular
              individual User. With respect to Google Analytics, although Google
              Analytics plants a permanent cookie on your web browser to
              identify you, the cookie cannot be used by anyone but Google.
              Google’s ability to use and share information collected by Google
              Analytics about your visits is restricted by the&nbsp;Google
              Analytics Terms of Use and the&nbsp;Google Privacy Policy. You can
              prevent Google Analytics from recognizing you on return visits
              by&nbsp;disabling cookies.
            </p>
            <p>&nbsp;</p>
            <h2>How do we use this information?</h2>
            <p>
              We use all of the information we have to help us provide and
              support our Services. Here is how:
            </p>
            <ol>
              <li>
                remember information so you will not have to re-enter it during
                your visit or the next time you visit the Service;
              </li>
              <li>
                provide, improve, test, and monitor the effectiveness of our
                Service;
              </li>
              <li>
                monitor metrics such as total number of visitors, traffic, and
                demographic patterns;
              </li>
              <li>diagnose or fix technology problems;</li>
              <li>develop and test new products and features; and</li>
            </ol>
            <p>&nbsp;</p>
            <h2>How is this information shared?</h2>
            <p>
              We will not rent or sell your information to third parties outside
              DELAPLUMES.
            </p>
            <h2>&nbsp;</h2>
            <h2>Change of control</h2>
            <p>
              If we sell or otherwise transfer part or the whole of DELAPLUMES
              or our assets to another organization (e.g., in the course of a
              transaction like a merger, acquisition, bankruptcy, dissolution,
              liquidation), your information collected through the Service may
              be among the items sold or transferred. The buyer or transferee
              will have to honor the commitments we have made in this Privacy
              Policy.
            </p>
            <p>&nbsp;</p>
            <h2>Legal requests and preventing harm</h2>
            <p>
              We may access, preserve and share your information in response to
              a legal request (like a search warrant, court order or subpoena)
              if we have a good faith belief that the law requires us to do so.
              We may also access, preserve and share information when we have a
              good faith belief it is necessary to: detect, prevent and address
              fraud and other illegal activity; to protect ourselves, you and
              others, including as part of investigations; and to prevent death
              or imminent bodily harm. Information we receive about you may be
              accessed, processed and retained for an extended period of time
              when it is the subject of a legal request or obligation,
              governmental investigation, or investigations concerning possible
              violations of our terms or policies, or otherwise to prevent harm.
            </p>
            <p>&nbsp;</p>
            <h2>Safety and security</h2>
            <p>
              DELAPLUMES has taken appropriate technical and organizational
              measures by using the latest technologies to protect your
              information against loss or unlawful processing. We use safeguards
              to help keep the information collected through the Service secure.
              However, DELAPLUMES cannot ensure the security of any information
              you transmit to DELAPLUMES or guarantee that information on the
              Service may not be accessed, disclosed, altered, or destroyed. We
              request you to do your part to help us. You are responsible for
              controlling access to emails between you and DELAPLUMES THE HAGUE,
              at all times. We are not responsible for the functionality,
              privacy, or security measures of any other organization.
            </p>
            <p>&nbsp;</p>
            <h2>International transfer</h2>
            <p>
              Your information may be transferred to, and maintained on
              computers located outside of your state, province, country or
              other governmental jurisdiction where the data protection laws may
              differ than those from your jurisdiction. If you are located
              outside Netherlands and choose to provide information to us,
              please note that we transfer the information to The Netherlands.
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>&nbsp;</p>
            <h2>Retention period</h2>
            <p>
              In accordance with the law, DELAPLUMES does not retain data any
              longer than is required for attaining the purposes for which they
              were collected.
            </p>
            <p>&nbsp;</p>
            <h2>Inspection and correction</h2>
            <p>
              If you wish to know which of your data DELAPLUMES THE HAGUE has
              recorded or if you wish to amend or remove data that you cannot
              amend via your account, please contact DELAPLUMES THE HAGUE.
            </p>
            <h2>&nbsp;</h2>
            <h2>Third-party applications, websites and services</h2>
            <p>
              We are not responsible for the practices employed by any
              applications, websites or services linked to or from our Service,
              including the information or content contained within them. Please
              remember that when you use a link to go from our Service to
              another application, website or service, our Privacy Policy does
              not apply to those third-party applications, websites or services.
              Your browsing and interaction on any third-party application,
              website or service, including those that have a link on our
              Services, are subject to that third party’s own rules and
              policies.
            </p>
            <p>&nbsp;</p>
            <h2>Children’s privacy</h2>
            <p>
              Our Service does not address anyone under the age of 13 (“
              <strong>Children</strong>“). We do not knowingly collect
              personally identifiable information from children under 13. If you
              are a parent or guardian and you are aware that your Children has
              provided us with personal information, please contact us. If we
              become aware that we have collected personal information from a
              child under age 13 without verification of parental consent, we
              take steps to remove that information from our servers.
            </p>
            <p>&nbsp;</p>
            <h2>Changes to this privacy policy</h2>
            <p>
              We may modify or update our Privacy Policy from time to time. We
              will notify you of any changes by posting the new Privacy Policy
              on this page. You are advised to review this Privacy Policy
              periodically for any changes. Changes to this Privacy Policy are
              effective when they are posted on this page.
            </p>
            <p>&nbsp;</p>
            <h2>How to contact us</h2>
            <p>
              If you have any questions about this Privacy Policy of the
              Service, please send an email to info@delaplumes.nl.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default CookiePolicyPage;
